import React, { Fragment, useState } from "react";
import GreenHeroBanner from "../../assets/images/green_hero.jpg";
import GeneralButton from "../generalButton/generalButton.component";
import AccordionGroup from "../accordion-group/accordion-group.component";
import Tabs from '../tabs/tabs.component';
import Accordion from '../accordion/accordion.component';
import {
  FAQ_TOP_THREE_DATA,
  FAQ_HVMS
} from '../../data/criteria.data';

const HmvsFaq = () => {

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  return (

    <Tabs
      tabs=""
      activeTabIndex={selectedTabIndex}
      setActiveTab={setSelectedTabIndex}
      additionalClassName='tabs--horizontal--no-border'
      tabContent={[
        <Fragment key={1}>
          <div className="hmvs-accordion">
            {
              FAQ_HVMS['accordionList'].map((d, index) => (
                <Accordion key={index} title={d.title} id={d.id} className="asd">
                  {d.children.map((item, ix) => (
                    <React.Fragment key={ix}>
                      {item}
                    </React.Fragment>
                  ))}
                </Accordion>
              ))
            }
          </div>

        </Fragment>
      ]


      }
    />

  );
};

export default HmvsFaq;