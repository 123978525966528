import React from "react";
import HmvsHeroBanner from "../../assets/images/hvms_hero.jpg";
import GeneralButton from "../generalButton/generalButton.component";


const HmvsHero = () => {
  return (
    <div className="hero-banner hero-banner--maintenance hero-banner--hmvs">
      <img className="hero-banner__background-image" src={HmvsHeroBanner} type="image/jpeg" alt="High Value Mortgage Service Banner Background" />
      <div className="hero-banner__outer">
        <section className="hero-banner__content hero-banner__content--hmvs">
          <h1>High Value
            Mortgage Service
          </h1>
          <p className="second">Introducing our new and improved High Value Mortgage Service (previously known as Large Loans).</p>
          <p className="second">A professional and personal service dedicated to loans over £1 million.</p>
        </section>
      </div>
    </div>
  );
};

export default HmvsHero;