import React from "react";

const HmvsTable = () => {
  return (
    <div className="hmvs-table" style={{ overflowX: "auto" }}>
      <table className="hmvs-table__table">
        <thead>
          <tr>
            <th>
              Type of Lending
            </th>
            <th>
              Repayment Basis
            </th>
            <th colSpan="4">
              Loan Size (LTV per cent)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="2" style={{ borderRight: "none" }}></td>
            <td rowSpan="5" style={{ borderLeft: "none", borderTop: "none" }}></td>
            <td>Over £1m up to and including £2m**</td>
            <td>Over £2m up to and including £3m</td>
            <td>Over £3m</td>
          </tr>
          <tr>
            <td rowSpan="2">
              New Mortgage
            </td>
            <td>Capital Repayment</td>
            <td>85</td>
            <td>75</td>
            <td>70</td>
          </tr>
          <tr>
            <td>Interest Only</td>
            <td>75*</td>
            <td>65*</td>
            <td>50</td>
          </tr>
          <tr>
            <td rowSpan="2">
              Remortgage
            </td>
            <td>Capital Repayment</td>
            <td>85</td>
            <td>75</td>
            <td>70</td>
          </tr>
          <tr>
            <td>Interest Only</td>
            <td>75*</td>
            <td>65*</td>
            <td>50</td>
          </tr>
          <tr>
            <td rowSpan="5">
              Additional borrowing
            </td>
            <td rowSpan="4">
              Capital Repayment (including unencumbered properties and existing mortgage customers borrowing more)
            </td>
            <td>Standard</td>
            <td>85</td>
            <td>75</td>
            <td>70</td>
          </tr>
          <tr>
            <td>Debt Consolidation</td>
            <td>80</td>
            <td>75</td>
            <td>70</td>
          </tr>
          <tr>
            <td>Any element of existing Interest Only borrowing</td>
            <td>85</td>
            <td>75</td>
            <td>70</td>
          </tr>
          <tr>
            <td>Business Use (must be subject to manual underwriting assessment by Underwriting Services)</td>
            <td>75</td>
            <td>75</td>
            <td>70</td>
          </tr>
          <tr>
            <td>Interest Only</td>
            <td rowSpan="2"></td>
            <td>60*</td>
            <td>60*</td>
            <td>50*</td>
          </tr>
          <tr>
            <td>Combined Repayment Type – New Mortgage, Remortgage and Additional Borrowing</td>
            <td>
              Part Capital Repayment/Part Interest Only <br />
              <br /> Please note, the maximum LTV for the interest only part of the loan is restricted to the ‘Interest
              Only’ maximum banding in line with the borrowing amount
            </td>
            <td>85</td>
            <td>75</td>
            <td>70</td>
          </tr>
          <tr>
            <td colSpan="8" style={{textAlign: "start"}}>
              *Interest Only borrowing against an Ad hoc Capital Repayment Plan is capped at 50 per cent LTV. Any
              additional borrowing up to the LTV limits above must be structured as follows;
              <ul>
                <li>On a Capital Repayment basis OR</li>
              </ul>
              On an interest only basis provided the remainder has a separate acceptable Repayment Plan(s) other than
              'Ad hoc Capital'.
              <br />
              <br />
              **For Flats, on Capital Repayment or Part and Part lending, between 75% - 85% LTV, a £1m maximum loan amount will apply.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default HmvsTable;
